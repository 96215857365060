"use client";

import { useRouter } from "next/navigation";
import supabase from "@/lib/supabase-client";

import Login from "@/components/login";

import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "@/utils/local-storage";
import { useEffect, useState } from "react";
import { message } from "@/lib/antd";

export default function LoginPage() {
  const router = useRouter();
  const [initialValues, setInitialValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const shouldRememberMe = getItemFromLocalStorage("shouldRememberMe");
    const email = shouldRememberMe ? getItemFromLocalStorage("email") : "";
    const password = shouldRememberMe
      ? getItemFromLocalStorage("password")
      : "";

    setInitialValues({
      remember: shouldRememberMe === "true",
      email,
      password,
    });
  }, []);

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const { email, password, remember } = values;
    const { error, data } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      console.warn("Error", error);
      messageApi.open({ type: "error", content: error.message });
      setIsLoading(false);
      return;
    }
    const shouldRememberMe = remember;
    const emailToStore = shouldRememberMe ? email : "";
    const passwordToStore = shouldRememberMe ? password : "";
    setItemInLocalStorage("shouldRememberMe", shouldRememberMe);
    setItemInLocalStorage("email", emailToStore);
    setItemInLocalStorage("password", passwordToStore);

    router.refresh();
    router.push("/dashboard");
  };

  return (
    <>
      {contextHolder}
      <Login
        initialValues={initialValues}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </>
  );
}


